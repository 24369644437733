import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog22/image1.png"
import image2 from "../../images/blog22/image2.jpg"
import image3 from "../../images/blog22/image3.jpg"
import image4 from "../../images/blog22/image4.jpg"
import image5 from "../../images/blog22/image5.png"
import image6 from "../../images/blog22/image6.jpg"
import image7 from "../../images/blog22/image7.jpg"
import image8 from "../../images/blog22/image8.jpg"



export default function FiveTipsToDitchYourBackPain(){
    return(
        <BlogPostOld
            src={image}
            title={"Herniated Disc CAN Heal"}
            summary={"I was interested in writing about this topic after one of my patients was told their disc herniation would never go back to normal. This patient had very minor symptoms of occasional numbness and pain that got better with time (as most do) but was involved in a car crash so wanted to consult with a physician.\n"}
            date={"September 5, 2022"}
            author={"Dr. Donald Mull, DC"}
        >
            <>
                <p>
                    I was interested in writing about this topic after one of my patients was
                    told their disc herniation would never go back to normal. This patient had
                    very minor symptoms of occasional numbness and pain that got
                    better with time (as most do) but was involved in a car crash so wanted to
                    consult with a physician.
                </p>
                <p>
                    The orthopedic specialist saw he had a disc extrusion (specific type of disc
                    herniation that you can see in the image in this article) after taking an
                    MRI and proceeded to tell him that his disc would not heal and never look
                    the same as it was before. This is not just poor advice, it is straight up
                    wrong.&nbsp;
                </p>
                <p>
                    It is advice like this that scares people into overprotecting their body
                    while removing themselves from activities that are important to them. Disc
                    herniations are very common and can be considered natural aging (hence why
                    we see them in populations with ZERO pain).&nbsp;
                </p>
                <p>
    <span style={{ textDecoration: "underline" }}>
      <strong>Are Disc Herniations Common?</strong>
    </span>
                </p>
                <p>
                    Disc herniations are quite common and&nbsp; their association or correlation
                    with pain has been broadly misunderstood. Many people walk around with disc
                    herniations with ZERO pain or disability.
                </p>
                <p>
                    When I say many people have disc herniations, I am not kidding. About half
                    the PAIN FREE population over 30 years of age have disc herniations. In a
                    room of 10 people that do not complain of any low back pain whatsoever,
                    about 5 are sure to have disc herniations.&nbsp;
                </p>
                <img src={image7} alt={"blog"}/>
                <p>
                    Physicians saying erroneous things like your disc will never look the same
                    again can lead one into thinking disc herniations are serious and need
                    intervention. I would like to set the record straight and say for the most
                    part, disc herniations are not serious and the best thing you can do is
                    return to valued physical activities as much as you can tolerate.&nbsp;
                </p>
                <p>
    <span style={{ textDecoration: "underline" }}>
      <strong>Are Disc Herniations Serious?</strong>
    </span>
                </p>
                <p>
                    Having a disc herniation does NOT mean you will have back pain and not
                    having a disc herniation does NOT mean you will have no pain. This concept
                    is important to remember when you have pain because this pain does not mean
                    you are hurting your herniated disc or making it worse. (Remember the
                    correlation between herniation and pain in general is VERY poor).&nbsp;
                </p>
                <p>
                    The size of disc herniation does not correlate strongly with the amount of
                    pain someone feels nor the amount of disability they experience. Having a
                    herniated disc nor the size of the herniation alone can tell us if that
                    person will have pain. You can have what is considered a big hernia and not
                    experience pain. Doesn’t sound that serious, does it?
                </p>
                <img src={image6} alt={"blog"}/>
                <img src={image2} alt={"blog"}/>
                <p>
                    So it may be safe to say that a change in the disc shape is a part of normal
                    aging or even just a normal occurrence of living your life. If you have been
                    diagnosed with a disc herniation via an MRI, this should give you some hope.
                    This isn’t meant to down talk how miserable symptoms of back pain can be and
                    the toll it can take on someone’s life. The struggle bus can be very real.
                    However I will argue that the presence of a disc herniation needs to
                    “change” in order for one to decrease their back pain. Besides, discs heal
                    over time just by living a normal life.&nbsp;
                </p>
                <p>
    <span style={{ textDecoration: "underline" }}>
      <strong>Can My Disc Herniation Heal?</strong>
    </span>
                </p>
                <p>
                    Discs absolutely heal. As a matter of fact we know that the larger the disc
                    herniation, the MORE likely it is that the disc will heal. Remember that
                    story I told you about my patient and how a physician told him that his disc
                    extrusion would never heal? Well science disagrees considering there is a
                    70% chance that within 12 months that herniation will have gotten smaller
                    AKA healed.&nbsp;
                </p>
                <img src={image4} alt={"blog"}/>
                <p>
                    Why do the larger disc heal at higher rates? Science has its theories - I
                    always picture our immune system going into “Oh Sh*t mode” and sending the
                    entire army at this thing. Remember that cartoon movie Osmosis Jones? That
                    is how I envision our macrophages, cytokines, etc (yeah, I am a nerd). Below
                    is a way more “realistic” depiction of the smack down your body puts on disc
                    herniations.
                </p>
                <img src={image3} alt={"blog"} />
                <p>
                    Moral of the story is, our discs one hundred percent can and oftentimes do
                    heal (at least to some degree) and the bigger the herniation, the more
                    likely it is to heal. If your doctor has told you otherwise, they are wrong.
                    This is not my opinion, this is science.
                </p>
                <p>
                    If you have Sciatica symptoms, this may complicate it a bit so we will stick to disc herniations. If you want to learn more about <a href={"https://firstprinciplesofmovement.com/duplicated-sciatica-advice-get-access-patients/"}>Sciatica here is a great article</a> that dives much deeper into details and provides great guidelines.
                </p>
                <p>
    <span style={{ textDecoration: "underline" }}>
      <strong>How Can I Heal My Herniated Disc?</strong>
    </span>
                </p>
                <p>
                    You can do nothing and live your normal life. That’s right, this study shows
                    between 3 months to a&nbsp; year later your herniated CAN get better and in
                    some cases COMPLETELY regress back to normal.&nbsp;
                </p>
                <p>
                    Doing nothing may not always be doable for you if there is concern or fear
                    when it comes to resuming your normal valued activities. That is completely
                    understandable (this stuff can be overwhelming).
                </p>
                <p>
                    This is a great time to seek out a professional that you can work TOGETHER
                    with to create a plan that works for you and your lifestyle. This plan
                    should aim to get you back on track to moving and living with less
                    limitations and becoming confident in your back to get back to kicking
                    life’s ass again.&nbsp;
                </p>
                <img src={image8} alt={"blog"}/>
                <p>
                    You CAN be physically active and it is SAFE. Some discomfort is normal; you
                    are still safe and you are likely not harming your body.&nbsp;
                </p>
                <p>
                    We have created a nice little starting point for you to kickstart yourself
                    back into doing your normal activities. It is{" "}
                    <a
                        href="https://kirehab.com/low-back-program"
                        target="_blank"
                        rel="noopener"
                    >
                        10 weeks of progressed
                    </a>{" "}
                    exercises we have seen help those who have back issues. Make sure you pair
                    it with doses of walking and other physical activities that you enjoy.
                    (Remember you do not need this exact template to get better - it is just
                    there to help you out, it isn’t a magic fix. You are STRONG and
                    RESILIENT).&nbsp;
                </p>
                <img src={image5} alt={"book"}/>
                <p>
                    <a
                        href="https://kirehab.com/low-back-program"
                        target="_blank"
                        rel="noopener"
                    >
                        This e-book
                    </a>{" "}
                    is designed to help those who do not have a long training background and may
                    be worried about movement in general. That said, it can be useful for those
                    meatheads like us - just progress at your own pace through the 10 week
                    program.&nbsp;
                </p>
                <p>Sources:</p>
                <p>
                    <a
                        href="https://pubmed.ncbi.nlm.nih.gov/25430861/"
                        target="_blank"
                        rel="noopener"
                    >
                        Brinjikji W, Luetmer PH, Comstock B, Bresnahan BW, Chen LE, Deyo RA,
                        Halabi S, Turner JA, Avins AL, James K, Wald JT, Kallmes DF, Jarvik JG.
                        Systematic literature review of imaging features of spinal degeneration in
                        asymptomatic populations. AJNR Am J Neuroradiol. 2015 Apr;36(4):811-6.
                        doi: 10.3174/ajnr.A4173. Epub 2014 Nov 27. PMID: 25430861; PMCID:
                        PMC4464797.
                    </a>
                </p>
                <p>
                    <a
                        href="https://pubmed.ncbi.nlm.nih.gov/25009200/"
                        target="_blank"
                        rel="noopener"
                    >
                        Chiu CC, Chuang TY, Chang KH, Wu CH, Lin PW, Hsu WY. The probability of
                        spontaneous regression of lumbar herniated disc: a systematic review. Clin
                        Rehabil. 2015 Feb;29(2):184-95. doi: 10.1177/0269215514540919. Epub 2014
                        Jul 9. PMID: 25009200.
                    </a>
                </p>
                <p>
                    <a
                        href="https://pubmed.ncbi.nlm.nih.gov/32905720/"
                        target="_blank"
                        rel="noopener"
                    >
                        Gupta A, Upadhyaya S, Yeung CM, Ostergaard PJ, Fogel HA, Cha T, Schwab J,
                        Bono C, Hershman S. Does Size Matter? An Analysis of the Effect of Lumbar
                        Disc Herniation Size on the Success of Nonoperative Treatment. Global
                        Spine J. 2020 Oct;10(7):881-887. doi: 10.1177/2192568219880822. Epub 2019
                        Oct 10. PMID: 32905720; PMCID: PMC7485083.
                    </a>
                </p>
                <p>
                    <a
                        href="https://pubmed.ncbi.nlm.nih.gov/35999644/"
                        target="_blank"
                        rel="noopener"
                    >
                        Yu P, Mao F, Chen J, Ma X, Dai Y, Liu G, Dai F, Liu J. Characteristics and
                        mechanisms of resorption in lumbar disc herniation. Arthritis Res Ther.
                        2022 Aug 23;24(1):205. doi: 10.1186/s13075-022-02894-8. PMID: 35999644;
                        PMCID: PMC9396855.
                    </a>
                </p>
            </>

        </BlogPostOld>

    )
}